import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../utilities';
import { isRequired } from '../../../validation';

export const FORM_NAME = 'CHANGE_PASSWORD_FORM';

class ChangePasswordForm extends React.PureComponent {
  render() {
    const { handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Field
              label={__('Terajšie heslo')}
              name="current_password"
              type="password"
              required
              validate={[isRequired]}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('Nové heslo')}
              name="password"
              type="password"
              required
              validate={[isRequired]}
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('Nové heslo znova')}
              name="password_again"
              type="password"
              required
              validate={[isRequired]}
              component={FormField}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(ChangePasswordForm);

export default connect()(connected);
