import { RECEIVE_ACL, RECEIVE_USER_SUCCESS, REMOVE_USER, REQUEST_USER, SET_BREADCRUMBS } from './constants';

export const applicationReducer = (
  state = {
    user: {
      isFetching: false,
      data: null,
    },
    acl: {},
    breadcrumbs: [],
  },
  action,
) => {
  switch (action.type) {
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload.breadcrumbs,
      };
    case REQUEST_USER:
      return {
        ...state,
        user: {
          isFetching: true,
        },
      };
    case RECEIVE_ACL:
      return {
        ...state,
        acl: action.payload.acl
      }
    case RECEIVE_USER_SUCCESS:
      return {
        ...state,
        user: {
          username: action.payload.username,
          token: action.payload.token,
          isFetching: false,
        },
      };
    case REMOVE_USER:
      return {
        authorized: false,
        isFetching: false,
      };
    default:
      return state;
  }
};
