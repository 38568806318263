import React from 'react';
import { Route } from 'react-router-dom';
import ForgotPassword from '../ForgotPassword';
import Login from '../Login';
import Register from '../Register';

class Application extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Route path="/" exact component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword} />
      </React.Fragment>
    );
  }
}

export default Application;
