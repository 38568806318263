import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import Api from '../../Api';
import { __ } from '../../utilities';
import { aclSelector } from '../ApplicationWrapper/selectors';
import { userDefinition } from '../Me/definitions';
import { appUserDataSelector } from '../Me/selectors';
import OrderForm from './form';

class Order extends React.PureComponent {
  state = {
    recipient: null,
    initialValues: {},
  };
  componentDidMount() {
    const {
      profile,
      match: {
        params: { id },
      },
      acl,
    } = this.props;
    if (!acl || !Object.keys(acl).length) {
      return;
    }
    if (id) {
      this.getOrder(id);
    }
    if (profile.role === 'spediter') {
      this.getClients();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.profile.role &&
      nextProps.profile.role &&
      nextProps.profile.role === 'spediter'
    ) {
      this.getClients();
    }
    if (
      nextProps.match.params.id &&
      (!this.props.acl || !this.props.acl.length) &&
      nextProps.acl
    ) {
      this.getOrder(nextProps.match.params.id);
    }
  }

  getClients = async () => {
    await Api.getClients();
  };

  getOrder = async orderId => {
    let order = null;
    if (this.props.acl && this.props.acl.adminGetOrder) {
      order = await Api.adminGetOrder(orderId);
    } else if (this.props.acl && this.props.acl.meOrder) {
      order = await Api.meOrder(orderId);
    }
    this.setState({
      initialValues: order,
    });
  };

  handleSubmit = async (data, dispatch, props) => {
    try {
      if (this.props.acl.adminGetOrder && this.props.match.params.id) {
        const res = await Api.adminUpdateOrder(this.props.match.params.id, {
          data: {
            delivery: data.delivery,
          },
        });
        if (res.routeId) {
          this.props.history.push(`/route/${res.routeId}`);
        } else {
          this.props.history.push('/');
        }
      } else if (!data.recipient._id) {
        const address = await Api.translateAddress({
          addressName: encodeURIComponent(
            data.recipient.address.formatted_address,
          ),
        });
        if (
          window.confirm(
            `${__(
              'Je toto presná adresa príjemcu? V prípade, že sa Vám adresu nepodarí nájsť, zadajte iba mesto a kompletnú adresu napíšte do kolónky Popis zásielky.',
            )} ${address.formatted_address}`,
          )
        ) {
          await Api.postOrder({
            data: {
              ...data,
              recipient: {
                ...data.recipient,
                address,
              },
            },
          });
          this.props.history.push('/');
        }
      } else {
        const { recipient, ...restData } = data;
        await Api.postOrder({
          data: {
            ...restData,
            recipientId: recipient._id,
          },
        });
        this.props.history.push('/');
      }
    } catch (e) {
      window.alert(
        __(
          `Adresu príjemcu sa nepodarilo nájsť. V prípade, že sa Vám adresu nepodarí nájsť, zadajte iba mesto a kompletnú adresu napíšte do kolónky Popis zásielky. Podrobnosť o chybe: ${JSON.stringify(
            e.details,
          )}`,
        ),
      );
    }
  };

  render() {
    const { profile, acl } = this.props;

    // if (
    //   moment().isBefore(moment('2021-12-07')) &&
    //   !this.props.match.params.id
    // ) {
    //   return null;
    // }

    if (
      this.props.match.params.id &&
      (!this.state.initialValues ||
        Object.keys(this.state.initialValues).length === 0)
    ) {
      return null;
    }
    if (this.props.match.params.id && acl && acl.adminGetOrder) {
      return (
        <OrderForm
          onSubmit={this.handleSubmit}
          data={this.state.initialValues}
          initialValues={this.state.initialValues}
          profile={this.state.initialValues.user}
          update
        />
      );
    }
    return (
      <OrderForm
        onSubmit={this.handleSubmit}
        data={this.state.initialValues}
        initialValues={this.state.initialValues}
        profile={profile}
      />
    );
  }
}

Order.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profile: userDefinition,
};

Order.defaultProps = {
  profile: {},
};

const mapStateToProps = state => ({
  profile: appUserDataSelector(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(Order);
