import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Label from '@sportnet/ui/lib/Label/Label';
import { rem } from 'polished';
import * as React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../utilities';
import { isRequired } from '../../validation';

const Radio = ({ input, ...restProps }) => (
  <div style={{ paddingBottom: rem(10) }}>
    <Label>
      <input type="radio" {...input} disabled={restProps.disabled} />
      &nbsp;
      <span style={{ fontWeight: 'normal', fontSize: rem(14) }}>
        {restProps.label}
      </span>
    </Label>
  </div>
);

class DeliveryForm extends React.PureComponent {
  render() {
    return (
      <Row>
        <Col xs={12}>
          <Field
            component={FormField}
            type="date"
            name="delivery.date"
            required
            readOnly={this.props.disabled}
            validate={[isRequired]}
            label={__('Orientačný dátum vyzdvihnutia')}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={FormField}
            name="delivery.description"
            type="textarea"
            required
            readOnly={this.props.disabled}
            validate={[isRequired]}
            label={__('Popis zásielky (približné rozmery, váha...)')}
          />
        </Col>
        <Col xs={12}>
          <Label required>{__('Spôsob platby')}</Label>
          <br />
          <div>
            <Field
              component={Radio}
              name="delivery.payment"
              type="radio"
              required
              validate={[isRequired]}
              readOnly={this.props.disabled}
              value="1"
              label={__('Pri prijatí zásielky')}
            />
            <Field
              component={Radio}
              name="delivery.payment"
              type="radio"
              required
              validate={[isRequired]}
              readOnly={this.props.disabled}
              value="2"
              label={__('Pri doručení zásielky')}
            />
          </div>
        </Col>
        {this.props.update && (
          <Row>
            <Col xs={12} s={3}>
              <Field
                component={FormField}
                name="delivery.count"
                type="text"
                // required
                // validate={[isRequired]}
                label={__('Počet')}
              />
            </Col>
            <Col xs={12} s={3}>
              <Field
                component={FormField}
                name="delivery.weight"
                type="text"
                // required
                // validate={[isRequired]}
                label={__('Váha v kg')}
              />
            </Col>
            <Col xs={12} s={3}>
              <Field
                component={FormField}
                name="delivery.price"
                type="text"
                // required
                // validate={[isRequired]}
                label={__('Cena')}
              />
            </Col>
            <Col xs={12} s={3}>
              <Field
                component={FormField}
                name="delivery.currency"
                type="select"
                // required
                // validate={[isRequired]}
                label={__('Mena')}
              >
                <option hidden />
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </Field>
            </Col>
          </Row>
        )}
      </Row>
    );
  }
}

export default DeliveryForm;
