import Button from '@sportnet/ui/lib/Button';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Segment from '@sportnet/ui/lib/Segment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import { withTheme } from 'styled-components';
import Api from '../../Api';
import Box from '../../Components/Box';
import { __ } from '../../utilities';
import RouteForm from './form';

const FORM_NAME = 'ROUTE_FORM';

class Route extends React.PureComponent {
  state = {
    drivers: [],
  }
  async componentDidMount() {
    this.getDrivers();
  }

  onSubmit = async (values) => {
    await Api.postRoute({ data: values });
    this.props.history.push(`/`);
  }

  getDrivers = async () => {
    const { users: drivers } = await Api.adminGetUsers({ role: 'driver' });
    this.setState({
      drivers,
    });
  }

  render() {
    const { theme } = this.props;
    const { drivers } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <Segment>
          <Row>
            <Col xs={12}>
              <Box
                header={__('Trasa')}
                color={theme.color.primary}
                buttons={[
                  <Button
                    success
                    onClick={() => {
                      this.props.dispatch(submit(FORM_NAME))
                    }}
                  >
                    {__('Uložiť trasu')}
                  </Button>
                ]}
              >
                <RouteForm form={FORM_NAME} onSubmit={this.onSubmit} drivers={drivers} />
              </Box>
            </Col>
          </Row>
        </Segment>
      </div>
    )
  }
}

Route.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default withTheme(connect()(withRouter(Route)));