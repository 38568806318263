/* eslint-disable */
import 'isomorphic-fetch';

class API {

  constructor() {
    this.baseUrl = null;
    this.token = null;
  }

  serializeQueryParams(parameters) {
    const str = [];
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(parameters[p])}`);
      }
    }
    return str.join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split("&").forEach(function(part) {
      let item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters)
        .forEach(function(parameterName) {
          const parameter = parameters.$queryParameters[parameterName];
          queryParameters[parameterName] = parameter;
        });
    }
    return queryParameters;
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(method, url, body, headers, queryParameters, form, checkFor401 = true) {
    const queryParams = queryParameters && Object.keys(queryParameters).length ? this.serializeQueryParams(queryParameters) : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    // contentTypeHeader[0] because nearly every header's value is set using array
    const contentTypeHeader = headers['Content-Type'];
    if (contentTypeHeader && contentTypeHeader[0] === 'multipart/form-data') {
      delete headers['Content-Type'];
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    return fetch(urlWithParams, {
        method,
        headers,
        body,
      })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          if (response.headers.get('Content-Type').includes('application/json')) {
            return response.json();
          } else if (response.headers.get('Content-Type').includes('application/pdf')) {
            return response.blob();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  setAuthHeaders(headerParams) {
    let headers = headerParams ? headerParams : {};
    if (this.token) {
      headers['Authorization'] = 'Bearer ' + this.token;
    }
    return headers;
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   */
  getClients(parameters = {}) {
    let path = '/clients';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.addressName - 
   */
  translateAddress(parameters = {}) {
    let path = '/address';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['addressName'] !== undefined) {
      queryParameters['addressName'] = parameters['addressName'];
    }

    if (parameters['addressName'] === undefined) {
      throw Error('Missing required  parameter: addressName');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfile(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  meProfileEdit(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  meGdprUpdate(parameters = {}) {
    let path = '/me/gdpr';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   */
  meAcl(parameters = {}) {
    let path = '/me/acl';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   */
  meAddresses(parameters = {}) {
    let path = '/me/addresses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  meCreateAddress(parameters = {}) {
    let path = '/me/addresses';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} addressId - 
   */
  meAddressDetail(addressId, parameters = {}) {
    let path = '/me/addresses/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   * @param {string} addressId - 
   */
  meAddressEdit(addressId, parameters = {}) {
    let path = '/me/addresses/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} addressId - 
   */
  meAddressRemove(addressId, parameters = {}) {
    let path = '/me/addresses/{addressId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{addressId}', addressId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   */
  meOrders(parameters = {}) {
    let path = '/me/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  postOrder(parameters = {}) {
    let path = '/me/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   */
  meOrder(orderId, parameters = {}) {
    let path = '/me/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   */
  meStornoOrder(orderId, parameters = {}) {
    let path = '/me/orders/{orderId}/storno';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.status - 
   * @param {string} parameters.routeId - 
   */
  adminGetOrders(parameters = {}) {
    let path = '/admin/orders';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    if (parameters['routeId'] !== undefined) {
      queryParameters['routeId'] = parameters['routeId'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   */
  adminGetOrder(orderId, parameters = {}) {
    let path = '/admin/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   * @param {string} orderId - 
   */
  adminUpdateOrder(orderId, parameters = {}) {
    let path = '/admin/orders/{orderId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   * @param {string} routeId - 
   */
  adminAssignOrder(orderId, routeId, parameters = {}) {
    let path = '/admin/orders/{orderId}/route/{routeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    path = path.replace('{routeId}', routeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   * @param {string} routeId - 
   */
  adminUnassignOrder(orderId, routeId, parameters = {}) {
    let path = '/admin/orders/{orderId}/route/{routeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    path = path.replace('{routeId}', routeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   */
  adminStornoOrder(orderId, parameters = {}) {
    let path = '/admin/orders/{orderId}/storno';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orderId - 
   * @param {string} status - 
   */
  adminChangeOrderStatus(orderId, status, parameters = {}) {
    let path = '/admin/orders/{orderId}/status/{status}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{orderId}', orderId);

    path = path.replace('{status}', status);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.status - 
   */
  getRoutes(parameters = {}) {
    let path = '/admin/routes';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  postRoute(parameters = {}) {
    let path = '/admin/routes';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} routeId - 
   */
  getRouteById(routeId, parameters = {}) {
    let path = '/admin/routes/{routeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{routeId}', routeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   * @param {string} routeId - 
   */
  editRoute(routeId, parameters = {}) {
    let path = '/admin/routes/{routeId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{routeId}', routeId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} routeId - 
   * @param {string} status - 
   */
  changeRouteStatus(routeId, status, parameters = {}) {
    let path = '/admin/routes/{routeId}/status/{status}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{routeId}', routeId);

    path = path.replace('{status}', status);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * Fetch User Based on Token
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} token - 
   */
  getTokenInfo(token, parameters = {}) {
    let path = '/me/{token}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  changePassword(parameters = {}) {
    let path = '/change-password';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * Creates New Login Session And Returns A Token
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  authorize(parameters = {}) {
    let path = '/auth/authorize';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * Destroy Token Info
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  unauthorize(parameters = {}) {
    let path = '/auth/unauthorize';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    if (parameters['data'] === undefined) {
      throw Error('Missing required  parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  forgotPassword(parameters = {}) {
    let path = '/auth/forgot-password';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  registerNewUser(parameters = {}) {
    let path = '/auth/register';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  lostPassword(parameters = {}) {
    let path = '/auth/lost-password';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.role - 
   */
  adminGetUsers(parameters = {}) {
    let path = '/admin/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['role'] !== undefined) {
      queryParameters['role'] = parameters['role'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   */
  adminCreateUser(parameters = {}) {
    let path = '/admin/users';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - 
   */
  adminGetUser(userId, parameters = {}) {
    let path = '/admin/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - 
   * @param {string} userId - 
   */
  adminEditUser(userId, parameters = {}) {
    let path = '/admin/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - 
   */
  adminDeactiveUser(userId, parameters = {}) {
    let path = '/admin/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

  /**
   * 
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} parameters.q - 
   */
  adminGetClients(parameters = {}) {
    let path = '/admin/clients';
    let body = {};
    let queryParameters = {};
    let headers = {};
    let form = {};

    headers = Object.assign({}, headers, this.setAuthHeaders(headers));
    headers['Accept'] = ['application/json'];
    headers['Content-Type'] = ['application/json'];

    if (parameters['offset'] !== undefined) {
      queryParameters['offset'] = parameters['offset'];
    }

    /** set default value **/
    queryParameters['limit'] = '100';

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit'];
    }

    if (parameters['q'] !== undefined) {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
  }

}

export default new API();