import cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Api from '../../Api';
import Application from '../Application';
import LoginRouter from '../LoginRouter';
import { getMyUserProfile } from '../Me/actions';
import { fetchUser, getAcl } from './actions';
import {
  aclSelector,
  isUserLoggedInSelector,
  userIsFetchingSelector,
} from './selectors';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

class ApplicationWrapper extends React.PureComponent {
  componentDidMount() {
    Api.setBaseUrl(process.env.REACT_APP_API_BASE_URL);
    Api._onResponseUnauthorized = () => {
      cookies.remove('access_token');
      window.location.replace('/');
    };
    const token = cookies.get('access_token');
    if (token) {
      Api.setToken(token);
      this.props.dispatch(fetchUser(token));
      this.props.dispatch(getMyUserProfile());
      this.props.dispatch(getAcl());
    } else {
      this.props.history.push('/');
    }
  }

  componentWillReceiveProps(nextProps) {
    const token = cookies.get('access_token');
    if (
      token &&
      (!this.props.acl || Object.keys(this.props.acl).length === 0)
    ) {
      nextProps.dispatch(getAcl());
      nextProps.dispatch(getMyUserProfile());
    }
  }

  render() {
    const { isUserLoggedIn, userIsFetching } = this.props;
    return (
      <Wrapper>
        {(isUserLoggedIn && !userIsFetching) || userIsFetching ? (
          <Application />
        ) : (
          <LoginRouter />
        )}
      </Wrapper>
    );
  }
}

ApplicationWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  userIsFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  acl: aclSelector(state),
  isUserLoggedIn: isUserLoggedInSelector(state),
  userIsFetching: userIsFetchingSelector(state),
});

export default connect(mapStateToProps)(withRouter(ApplicationWrapper));
