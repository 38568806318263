import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Field } from 'redux-form';
import { __ } from '../../utilities';
import { isRequired } from '../../validation';

class ProfileForm extends React.PureComponent {
  render() {
    const { readOnly } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <Field
            label={__('Identifikátor')}
            readOnly
            name="user.identifier"
            component={FormField}
          />
        </Col>
        <Col xs={12} s={3}>
          <Field
            label={__('Titul')}
            readOnly={readOnly}
            name="user.title"
            component={FormField}
          />
        </Col>
        <Col xs={12} s={4}>
          <Field
            label={__('Meno')}
            name="user.name"
            {...!readOnly && { required: true }}
            validate={[isRequired]}
            component={FormField}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={12} s={5}>
          <Field
            label={__('Priezvisko')}
            name="user.surname"
            {...!readOnly && { required: true }}
            validate={[isRequired]}
            component={FormField}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={12} s={6}>
          <Field
            label={__('E-mail')}
            name="user.email"
            type="email"
            readOnly
            component={FormField}
          />
        </Col>
        <Col xs={12} s={6}>
          <Field
            label={__('Telefónne číslo')}
            name="user.phone"
            {...!readOnly && { required: true }}
            validate={[isRequired]}
            component={FormField}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    );
  }
}

ProfileForm.propTypes = {
  readOnly: PropTypes.bool,
};

ProfileForm.defaultProps = {
  readOnly: false,
};

export default ProfileForm;
