import Button from '@sportnet/ui/lib/Button';
import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Message from '@sportnet/ui/lib/Message';
import Modal, { ModalContent } from '@sportnet/ui/lib/Modal';
import { ModalActions } from '@sportnet/ui/lib/Modal/modal';
import Segment from '@sportnet/ui/lib/Segment';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, Field, reduxForm, submit } from 'redux-form';
import { getProp } from 'sportnet-utilities';
import styled, { css } from 'styled-components';
import Api from '../../Api';
import Box from '../../Components/Box';
import { __ } from '../../utilities';
import { isRequired } from '../../validation';
import { receiveUserProfileSuccess } from '../Me/actions';
import DeliveryForm from './DeliveryForm';
import ProfileForm from './ProfileForm';
import RecipientForm from './RecipientForm';
import OrderRouteForm, { FORM_NAME as ROUTE_FORM_NAME } from './RouteForm';

const FORM_NAME = 'ORDER_FORM';

const Wrapper = styled.div`
  width: 100%;
`;

const Tags = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
`;
const Tag = styled.div`
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  font-size: 12px;
  cursor: pointer;
  margin: 1px 0;
  ${({ active }) => {
    if (active) {
      return css`
        background: ${({ theme }) => theme.color.primary};
        color: white;
      `;
    }
    return css``;
  }} &:hover {
    background: ${({ theme }) => theme.color.primary};
    color: white;
  }
  &:hover:after {
    border-left: 10px solid ${({ theme }) => theme.color.primary};
  }
  &:before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 10px;
  }
  &:after {
    background: #fff;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #eee;
    border-top: 13px solid transparent;
    ${({ active }) => {
      if (active) {
        return css`
          border-left: 10px solid ${({ theme }) => theme.color.primary};
        `;
      }
      return css``;
    }} content: '';
    position: absolute;
    right: 0;
    top: 0;
  }
`;

class OrderForm extends React.PureComponent {
  state = {
    modalOpened: false,
    addressIsFetching: false,
    recipient: null,
  };

  componentDidMount() {
    this.setRecipient();
  }

  componentWillReceiveProps(nextProps) {
    if (getProp(nextProps.data || {}, ['recipient'])) {
      this.setRecipient(nextProps);
    }
  }

  setRecipient = (props = this.props) => {
    if (getProp(props.data || {}, ['recipient'])) {
      this.setState({
        recipient: props.data.recipient._id,
      });
      props.dispatch(change(FORM_NAME, 'recipient', props.data.recipient));
    }
  };

  searchAddress = async (i) => {
    this.setState({
      addressIsFetching: true,
    });
    try {
      const address = await Api.translateAddress({
        addressName: encodeURIComponent(i),
      });
      if (
        window.confirm(
          `${__(
            'Je toto presná adresa? V prípade, že sa Vám adresu nepodarí nájsť, zadajte iba mesto a kompletnú adresu napíšte do kolónky Popis zásielky.',
          )} ${address.formatted_address}`,
        )
      ) {
        const userProfile = await Api.meCreateAddress({
          data: { name: encodeURIComponent(address.formatted_address) },
        });
        this.props.dispatch(receiveUserProfileSuccess(userProfile));
        const newAddress = userProfile.addresses.find(
          (a) => a.address === address.formatted_address,
        );
        this.props.dispatch(change(FORM_NAME, 'address', newAddress));
      }
    } catch (e) {
      alert(
        __(
          'Adresu sa nepodarilo nájsť. V prípade, že sa Vám adresu nepodarí nájsť, zadajte iba mesto a kompletnú adresu napíšte do kolónky Popis zásielky.',
        ),
      );
    } finally {
      this.setState({
        addressIsFetching: false,
      });
    }
  };

  submitOrderRouteForm = async (values) => {
    this.props.dispatch(submit(FORM_NAME));
    if (!values.routeId) {
      await Api.adminUnassignOrder(this.props.data._id, '0');
    } else {
      await Api.adminAssignOrder(this.props.data._id, values.routeId);
    }
    this.props.history.push('/');
  };

  stornoOrder = async (orderId) => {
    this.setState({
      storno: true,
    });
    await Api.adminStornoOrder(orderId);
    this.props.history.push('/');
  };

  render() {
    const disabled = !!getProp(this.props.data || {}, ['_id']);
    return (
      <Wrapper>
        <Segment>
          {!this.props.match.params._id && (
            <Message
              warning
              title={__(
                'V prípade, že si želáte upraviť svoje údaje len pre túto objednávku, môžete svoje údaje prepísať priamo tu.',
              )}
            />
          )}
          {(!disabled ||
            (disabled && this.props.data._id && this.props.update)) && (
            <React.Fragment>
              <Row>
                <Col xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {this.props.update &&
                      this.props.data.status !== 'STORNO' && (
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                __('Skutočne si želáte stornovať zásielku?'),
                              )
                            ) {
                              this.stornoOrder(this.props.data._id);
                            }
                          }}
                          danger
                        >
                          {__('Stornovať zásielku')}
                        </Button>
                      )}
                    &nbsp;
                    {this.props.update &&
                      this.props.data.status !== 'STATUS' && (
                        <Button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              modalOpened: true,
                            });
                          }}
                          warning
                        >
                          {__('Priradiť zásielku')}
                        </Button>
                      )}
                    &nbsp;
                    {this.props.data.status !== 'STORNO' && (
                      <Button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.dispatch(submit(FORM_NAME));
                        }}
                        success
                      >
                        {__('Uložiť zásielku')}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <br />
            </React.Fragment>
          )}
          {/* <Row>
            <Col xs={12}>
              <div style={{
                background: '#fff',
                paddingTop: '10px',
                boxShadow: '0px 0.0625rem 0.3125rem 0 rgba(0,0,0,0.1)',
                paddingLeft: '10px'
              }}
              >
                <Field
                  component={FormField}
                  name="vop"
                  validate={[isRequired]}
                  label={__('Súhlasím s prepravnými podmienkami')}
                  type="checkbox"
                />
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} s={6}>
              <Box
                color={({ theme }) => theme.color.success}
                header={__('Odosielateľ')}
                buttons={
                  !!this.props.data.user && [
                    <a href={`tel: ${this.props.data.user.phone}`}>
                      <Button success>{__('Zavolať')}</Button>
                    </a>,
                  ]
                }
              >
                <ProfileForm
                  readOnly={
                    !!Object.keys(this.props.data).length && !this.props.update
                  }
                />
                <Field
                  component={FormField}
                  type="theselect"
                  name="address"
                  disabled={disabled}
                  required
                  validate={[isRequired]}
                  placeholder={__('Vyberte alebo napíšte novú adresu  ')}
                  label={__('Adresa vyzdvihnutia')}
                  createNewOption={this.searchAddress}
                  parse={(item) => {
                    if (!item) {
                      return '';
                    }
                    const option = this.props.profile.addresses.find(
                      (o) => o._id === item.value,
                    );

                    if (!option) {
                      return '';
                    }
                    return option;
                  }}
                  format={(i) => {
                    if (i) {
                      const option = this.props.profile.addresses.find(
                        (o) => o._id === (i._id || ''),
                      );
                      if (option) {
                        return {
                          value: i._id || '',
                          label: option.address,
                        };
                      }
                    }
                    return '';
                  }}
                  options={getProp(
                    this.props.profile || {},
                    ['addresses'],
                    [],
                  ).map((a) => ({
                    label: a.address,
                    value: a._id,
                  }))}
                />
              </Box>
            </Col>
            <Col xs={12} s={6}>
              <Box
                color={({ theme }) => theme.color.danger}
                header={__('Prijímateľ')}
                buttons={
                  !!this.props.data.recipient && [
                    <a href={`tel: ${this.props.data.recipient.phone}`}>
                      <Button success>{__('Zavolať')}</Button>
                    </a>,
                  ]
                }
              >
                {!disabled && (
                  <Tags>
                    {getProp(this.props.profile || {}, ['recipient'], []).map(
                      (recipient) => (
                        <Tag
                          onClick={() => {
                            if (this.state.recipient === recipient._id) {
                              this.setState({
                                recipient: null,
                              });
                              this.props.dispatch(
                                change(FORM_NAME, 'recipient', {}),
                              );
                            } else {
                              this.setState({
                                recipient: recipient._id,
                              });
                              this.props.dispatch(
                                change(FORM_NAME, 'recipient', recipient),
                              );
                            }
                          }}
                          active={this.state.recipient === recipient._id}
                        >
                          {recipient.title ? `${recipient.title} ` : ''}
                          {recipient.name} {recipient.surname}
                        </Tag>
                      ),
                    )}
                  </Tags>
                )}
                <RecipientForm
                  disabled={!!this.state.recipient || disabled}
                  readOnly
                />
              </Box>
            </Col>
            <Col xs={12} s={6}>
              <Box
                color={({ theme }) => theme.color.primary}
                header={__('Informácie o zásielke')}
              >
                <DeliveryForm
                  update={this.props.update}
                  disabled={disabled && !this.props.update}
                />
              </Box>
            </Col>
          </Row>
        </Segment>
        {this.props.update && (
          <Modal
            isOpen={this.state.modalOpened}
            handleClose={() => {
              this.setState({
                modalOpened: false,
              });
            }}
          >
            <ModalContent>
              <Segment>
                <OrderRouteForm onSubmit={this.submitOrderRouteForm} />
              </Segment>
            </ModalContent>
            <ModalActions>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    modalOpened: false,
                  });
                }}
                type="button"
                danger
              >
                {__('Zatvoriť')}
              </Button>
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  this.props.dispatch(submit(ROUTE_FORM_NAME));
                }}
                type="button"
                success
              >
                {__('Priradiť')}
              </Button>
            </ModalActions>
          </Modal>
        )}
        {/* <Modal isOpen handleClose={() => {
          //
        }}>
          <ModalContent>
            <VOP />
          </ModalContent>
        </Modal> */}
      </Wrapper>
    );
  }
}

OrderForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  if (!Object.keys(props.data).length) {
    const { recipient, recipients, addresses, ...restProfile } = props.profile;
    return {
      initialValues: {
        user: restProfile,
      },
    };
  }
  return {};
};

export default connect(mapStateToProps)(
  withRouter(
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: true,
    })(OrderForm),
  ),
);
