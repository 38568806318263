import Button from '@sportnet/ui/lib/Button';
import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, Form, isSubmitting, reduxForm } from 'redux-form';
import { __ } from '../../utilities';
import { isEmail, isRequired } from '../../validation';

const FORM_NAME = 'REGISTRATION_FORM';

class RegistrationForm extends React.PureComponent {
  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} s={12}>
            <Field
              name="usermail"
              required
              validate={[isEmail, isRequired]}
              label={__('E-mailová adresa')}
              component={FormField}
            />
          </Col>
          <Col xs={12}>
            <Button block primary loading={submitting}>
              {__('Obnoviť heslo')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(RegistrationForm);

const mapStateToProps = state => ({
  submitting: isSubmitting(FORM_NAME)(state),
});

export default connect(mapStateToProps)(connected);
