import { __ } from './utilities';

export const isRequired = value => {
  if (!value) {
    return __('Pole je povinné');
  }
  return null;
};

export const isEmail = value => {
  if (
    !value ||
    !value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  ) {
    return __('Zadajte platnú emailovú adresu');
  }
  return null;
};
