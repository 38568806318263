import { browserHistory } from 'react-router';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { listReducer } from 'redux-list/lib/ducks';
import thunkMiddleware from 'redux-thunk';
import { applicationReducer } from './Containers/ApplicationWrapper/reducer';
import { meReducer } from './Containers/Me/reducer';

let composeEnhancers;
if (typeof window !== 'undefined') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

export default function configureStore(preloadedState) {
  let initialState = preloadedState;
  if (!preloadedState) {
    initialState = {};
  }
  return createStore(
    combineReducers(
      Object.assign(
        {},
        {
          routing: routerReducer,
          form: formReducer,
          list: listReducer,
          application: applicationReducer,
          me: meReducer,
        },
      ),
    ),
    initialState,
    composeEnhancers(
      applyMiddleware(routerMiddleware(browserHistory), thunkMiddleware),
    ),
  );
}
