import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import moment from 'moment';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Api from '../../Api';
import { __ } from '../../utilities';

export const FORM_NAME = 'ORDER_ROUTE_FORM';

class OrderRouteForm extends React.PureComponent {
  state = {
    routes: []
  }

  componentDidMount() {
    this.getRoutes();
  }

  getRoutes = async () => {
    const { routes } = await Api.getRoutes({ status: 'WAITING' });
    this.setState({
      routes,
    })
  }

  render() {
    return (
      <Row>
        <Col xs={12}>
          <Field
            component={FormField}
            name="routeId"
            label={__('Trasa')}
            type="select"
          >
            <option hidden />
            {this.state.routes.map(route => (
              <option value={route._id}>{`${route.driver.name} ${route.driver.surname} (${moment(route.date).format('DD.MM.YYYY')})`}</option>
            ))}
          </Field>
        </Col>
      </Row>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
})(OrderRouteForm);