import { getListResults } from 'redux-list';
import { createSelector } from 'reselect';

const commonDomainSelector = state => state.me;
const appUserDomainSelector = createSelector(
  commonDomainSelector,
  substate => substate.user,
);

export const appUserIsFetchingSelector = createSelector(
  appUserDomainSelector,
  substate => substate.isFetching,
);

export const appUserDataSelector = createSelector(
  appUserDomainSelector,
  substate => substate.data,
);

const ordersSelector = createSelector(
  commonDomainSelector,
  substate => substate.orders,
);

export const ordersCurrentListSelector = listName =>
  createSelector(ordersSelector, getListResults(listName), (entities, ids) => {
    const list = (ids || []).reduce((acc, id) => {
      if (entities.items[id]) {
        return [...acc, entities.items[id]];
      }
      return acc;
    }, []);
    return list;
  });

export const ordersStatistics = createSelector(
  ordersSelector,
  substate => substate.statistics,
);
