import PropTypes from 'prop-types';
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../../Components/Layout';
import Clients from '../Clients';
import Dashboard from '../Me/Dashboard';
import MeProfile from '../Me/Profile';
import Order from '../Order';
import RouteIndex from '../Route';
import RouteDetail from '../Route/detail';

const Wrapper = styled.div`
  width: 100%;
`;

class Application extends React.Component {
  render() {
    return (
      <Wrapper>
        <Layout pathname={this.props.location.pathname}>
          <Route path="/" exact component={Dashboard} />
          <Route path="/me/profile" exact component={MeProfile} />
          <Route path="/clients" exact component={Clients} />
          <Route path="/order" exact component={Order} />
          <Route path="/order/:id" component={Order} />
          <Route path="/route" exact component={RouteIndex} />
          <Route path="/route/:routeId" component={RouteDetail} />
        </Layout>
      </Wrapper>
    );
  }
}

Application.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Application);
