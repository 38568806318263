import Button from '@sportnet/ui/lib/Button';
import FormField from '@sportnet/ui/lib/FormField';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Segment from '@sportnet/ui/lib/Segment';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { isSubmitting, reset, SubmissionError, submit } from 'redux-form';
import { getProp } from 'sportnet-utilities';
import styled from 'styled-components';
import Api from '../../../Api';
import Box from '../../../Components/Box';
import { __ } from '../../../utilities';
import { aclSelector } from '../../ApplicationWrapper/selectors';
import { receiveUserProfileSuccess } from '../actions';
import { userDefinition } from '../definitions';
import { appUserDataSelector, appUserIsFetchingSelector } from '../selectors';
import ChangePasswordForm, {
  FORM_NAME as changePasswordFormName,
} from './change-password-form';
import MeProfileForm, { FORM_NAME as profileFormName } from './profile-form';

const AdressFormWrapper = styled.div`
  position: relative;
  & > button {
    position: absolute;
    right: 0;
    top: ${rem(19)};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const Td = styled.td`
  font-size: ${rem(14)};
  color: #555555;
  padding: ${rem(10)};
`;
const Th = styled.th`
  font-weight: bold;
  text-align: left;
  font-size: ${rem(14)};
  padding: ${rem(10)};
  background: #eee;
  color: rgb(85, 85, 85);
  border-color: rgb(238, 238, 238);
  border-width: ${rem(1)};
`;
const SmallButton = styled(Button)`
  min-height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 13px;
`;

class MeProfile extends React.PureComponent {
  state = {
    addressDraft: '',
  };

  searchAddress = async () => {
    this.setState({
      addressIsFetching: true,
    });
    try {
      const userProfile = await Api.meCreateAddress({
        data: { name: encodeURIComponent(this.state.addressDraft) },
      });
      this.props.dispatch(receiveUserProfileSuccess(userProfile));
      this.setState({
        addressDraft: '',
      });
    } catch (e) {
      alert(__('Adresu sa nepodarilo nájsť'));
    } finally {
      this.setState({
        addressIsFetching: false,
      });
    }
  };

  removeAddress = async id => {
    this.setState({
      removingAddress: id,
    });
    try {
      const userProfile = await Api.meAddressRemove(id);
      this.props.dispatch(receiveUserProfileSuccess(userProfile));
    } catch (e) {
      alert(__('Adresu sa nepodarilo odstrániť'));
    } finally {
      this.setState({
        removingAddress: null,
      });
    }
  };

  submit = async data => {
    const { dispatch } = this.props;
    const {
      _id,
      email,
      gdprAcceptedOn,
      recipient,
      addresses,
      role,
      oldUserId,
      oldClientId,
      recipients,
      ...restData
    } = data;
    const userProfile = await Api.meProfileEdit({ data: restData });
    dispatch(receiveUserProfileSuccess(userProfile));
  };

  submitChangePassword = async data => {
    if (data.password.trim() !== data.password_again.trim()) {
      throw new SubmissionError({
        password: __('Heslá sa nezhodujú'),
        password_again: __('Heslá sa nezhodujú'),
      });
    }
    try {
      await Api.changePassword({
        data: {
          old_password: data.current_password.trim(),
          password: data.password.trim(),
        },
      });
      this.props.dispatch(reset(changePasswordFormName));
    } catch (e) {
      if (
        getProp(e, ['details', 'description']) === 'OLD_PASSWORD_IS_INCORRECT'
      ) {
        alert(__('Zadané heslo je nesprávne'));
      } else {
        alert(__('Zmena hesla sa nepodarila'));
      }
    }
  };

  render() {
    const {
      profile,
      isFetchingProfile,
      isSubmittingProfileChange,
      isSubmittingPasswordChange,
      acl,
    } = this.props;
    const isClient = !!getProp(acl, ['meAddresses']);
    return (
      <Segment>
        <Row>
          <Col xs={12} s={6}>
            <Segment>
              <Box
                loading={isFetchingProfile}
                color={({ theme }) => theme.color.primary}
                header={__('Úprava profilu')}
                buttons={[
                  <Button
                    primary
                    icon="file"
                    loading={isSubmittingProfileChange}
                    onClick={() => {
                      this.props.dispatch(submit(profileFormName));
                    }}
                  >
                    {__('Uložiť')}
                  </Button>,
                ]}
              >
                <MeProfileForm
                  initialValues={profile || {}}
                  onSubmit={this.submit}
                />
              </Box>
            </Segment>
          </Col>
          <Col xs={12} s={6}>
            <Segment>
              <Box
                color={({ theme }) => theme.color.danger}
                header={__('Zmena hesla')}
                buttons={[
                  <Button
                    danger
                    icon="check"
                    loading={isSubmittingPasswordChange}
                    onClick={() => {
                      this.props.dispatch(submit(changePasswordFormName));
                    }}
                  >
                    {__('Zmeniť heslo')}
                  </Button>,
                ]}
              >
                <ChangePasswordForm onSubmit={this.submitChangePassword} />
              </Box>
            </Segment>
          </Col>
          {isClient && (
            <Col xs={12}>
              <Segment>
                <Box
                  color={({ theme }) => theme.color.success}
                  header={__('Pridať adresu')}
                >
                  <Row>
                    <Col xs={12}>
                      <AdressFormWrapper>
                        <FormField
                          value={this.state.addressDraft}
                          onChange={e => {
                            this.setState({
                              addressDraft: e.target.value,
                            });
                          }}
                          label={__('Adresa')}
                        />
                        <Button
                          onClick={() => {
                            this.searchAddress();
                          }}
                          loading={this.state.addressIsFetching}
                          success
                          icon="plus"
                        />
                      </AdressFormWrapper>
                    </Col>
                  </Row>
                </Box>
              </Segment>
            </Col>
          )}
        </Row>
        <Row>
          {isClient && (
            <Col xs={12}>
              <Segment>
                <Box
                  color={({ theme }) => theme.color.success}
                  header={__('Moje adresy')}
                >
                  <Table>
                    <thead>
                      <tr>
                        <Th>{__('Adresa')}</Th>
                        <Th />
                      </tr>
                    </thead>
                    <tbody>
                      {getProp(profile, ['addresses'], []).map(address => {
                        return (
                          <tr>
                            <Td>{address.address}</Td>
                            <Td style={{ textAlign: 'right' }}>
                              <SmallButton
                                danger
                                loading={
                                  this.state.removingAddress === address._id
                                }
                                onClick={() => {
                                  this.removeAddress(address._id);
                                }}
                              >
                                {__('Zmazať adresu')}
                              </SmallButton>
                            </Td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Box>
              </Segment>
            </Col>
          )}
        </Row>
      </Segment>
    );
  }
}

MeProfile.propTypes = {
  dispatch: PropTypes.func.isRequired,
  profile: userDefinition,
  isSubmittingProfileChange: PropTypes.bool.isRequired,
  isSubmittingPasswordChange: PropTypes.bool.isRequired,
  isFetchingProfile: PropTypes.bool.isRequired,
};

MeProfile.defaultProps = {
  profile: null,
};

const mapStateToProps = state => ({
  profile: appUserDataSelector(state),
  isFetchingProfile: appUserIsFetchingSelector(state),
  isSubmittingProfileChange: isSubmitting(profileFormName)(state),
  isSubmittingPasswordChange: isSubmitting(changePasswordFormName)(state),
  acl: aclSelector(state),
});

export default connect(mapStateToProps)(MeProfile);
