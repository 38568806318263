import {
  RECEIVE_USER_ORDERS_SUCCSES,
  RECEIVE_USER_PROFILE_FAILURE,
  RECEIVE_USER_PROFILE_SUCCESS,
  REQUEST_USER_PROFILE,
} from './constants';

export const meReducer = (
  state = {
    user: {
      data: undefined,
      isFetching: false,
    },
    orders: {
      items: [],
      total: 0,
      next_offset: null,
      statistics: null,
    },
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_USER_PROFILE:
      return {
        ...state,
        user: {
          isFetching: true,
        },
      };
    case RECEIVE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          data: action.payload.userProfile,
          isFetching: false,
        },
      };
    case RECEIVE_USER_PROFILE_FAILURE:
      return {
        ...state,
        user: {
          isFetching: false,
        },
      };
    case RECEIVE_USER_ORDERS_SUCCSES:
      return {
        ...state,
        orders: {
          total: action.payload.total,
          next_offset: action.payload.next_offset,
          items: action.payload.items.reduce((acc, next) => {
            return { ...acc, [next._id]: next };
          }, {}),
          statistics: action.payload.statistics,
        },
      };
    default:
      return state;
  }
};
