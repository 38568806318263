import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../utilities';
import { isRequired } from '../../../validation';

export const FORM_NAME = 'ME_PROFILE_FORM';

class MeProfileForm extends React.PureComponent {
  render() {
    const { handleSubmit, readOnly } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} s={3}>
            <Field
              label={__('Titul')}
              readOnly={readOnly}
              name="title"
              component={FormField}
            />
          </Col>
          <Col xs={12} s={4}>
            <Field
              label={__('Meno')}
              name="name"
              {...!readOnly && { required: true }}
              validate={[isRequired]}
              component={FormField}
              readOnly={readOnly}
            />
          </Col>
          <Col xs={12} s={5}>
            <Field
              label={__('Priezvisko')}
              name="surname"
              {...!readOnly && { required: true }}
              validate={[isRequired]}
              component={FormField}
              readOnly={readOnly}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('E-mail')}
              name="email"
              type="email"
              readOnly
              component={FormField}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              label={__('Telefónne číslo')}
              name="phone"
              {...!readOnly && { required: true }}
              validate={[isRequired]}
              component={FormField}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

MeProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

MeProfileForm.defaultProps = {
  readOnly: false,
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(MeProfileForm);

export default connect()(connected);
