import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';

export const Container = styled.div`
  width: 100%;
  max-width: ${rem(1000)};
  margin: auto;
  display: flex;
`;

class Layout extends React.Component {
  render() {
    const { children, pathname } = this.props;
    return (
      <React.Fragment>
        <Menu pathname={pathname} />
        <Container>{children}</Container>
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default Layout;
