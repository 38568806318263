import { rem } from 'polished';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
import Icon from '../../Containers/Me/Dashboard/icons';

const Segment = styled.div`
  border-radius: 0.25rem;
  margin-bottom: 0.625rem;
  background: #fff;
  box-shadow: 0px 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
`;
const Wrapper = styled.div`
  display: flex;
`;
const IconBlock = styled.div`
  padding: ${rem(30)} ${rem(15)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
  color: white;
`;
const DataBlock = styled.div`
  text-align: right;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(5)} ${rem(15)};
  color: ${({ color }) => color};
`;
const Count = styled.div`
  font-weight: bold;
  font-size: ${rem(32)};
`;
const Label = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${rem(12)};
`;

class InfoBlock extends React.PureComponent {
  render() {
    const { color, icon, count, label } = this.props;
    return (
      <Segment>
        <Wrapper>
          <IconBlock color={color}>
            <Icon name={icon} color='#fff' size={20} />
          </IconBlock>
          <DataBlock color={color}>
            <Count>{count}</Count>
            <Label>{label}</Label>
          </DataBlock>
        </Wrapper>
      </Segment>
    );
  }
}

InfoBlock.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
};

InfoBlock.defaultProps = {
  count: 0,
};

export default InfoBlock;
