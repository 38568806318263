import cookies from 'js-cookie';
import Api from '../../Api';
import { ERROR_CLEAR, ERROR_RECEIVED, RECEIVE_ACL, RECEIVE_CODELIST_SUCCESS, RECEIVE_USER_SUCCESS, REMOVE_USER, REQUEST_USER, SET_BREADCRUMBS, SUCCESS_RECEIVED } from './constants';

export const receiveError = error => ({
  type: ERROR_RECEIVED,
  payload: {
    error,
  },
});

export const receiveSuccess = message => ({
  type: SUCCESS_RECEIVED,
  payload: {
    message,
  },
});

export const clearErrors = () => ({
  type: ERROR_CLEAR,
  payload: {},
});

const requestUser = () => ({
  type: REQUEST_USER,
});

const receiveUserSuccess = (username, token) => ({
  type: RECEIVE_USER_SUCCESS,
  payload: {
    username,
    token,
  },
});

export const fetchUser = token => {
  return dispatch => {
    dispatch(requestUser());
    return Api.getTokenInfo(token)
      .then(result => {
        Api.setToken(result.token);
        dispatch(receiveUserSuccess(result.username, result.token));
      })
      .catch(error => {
        console.error(error);
        cookies.remove('access_token');
      });
  };
};

export const removeUser = () => ({
  type: REMOVE_USER,
  payload: {},
});

export const unauthorize = (username, token) => {
  return dispatch => {
    return Api.unauthorize({
      data: {
        username,
        token,
      },
    })
      .then(() => {
        cookies.remove('access_token');
        dispatch(removeUser());
      })
      .catch(error => {
        console.error(error);
        dispatch(receiveError('Odhlásenie sa nepodarilo.'));
      });
  };
};

export const setBreadrumbs = breadcrumbs => ({
  type: SET_BREADCRUMBS,
  payload: {
    breadcrumbs,
  },
});

export const receiveCodelistSuccess = (type, codelistItems) => ({
  type: RECEIVE_CODELIST_SUCCESS,
  payload: {
    type,
    codelistItems,
  },
});

export const getCodelistById = codelistId => async dispatch => {
  try {
    const result = await Api.getCodelistById(codelistId);
    dispatch(receiveCodelistSuccess(codelistId, result.items));
  } catch (e) {
    dispatch(receiveError(`Nepodarilo sa získať zoznam ${codelistId}`));
  }
};

export const receiveAcl = acl => ({
  type: RECEIVE_ACL,
  payload: {
    acl
  }
});

export const getAcl = () => async dispatch => {
  const acl = await Api.meAcl();
  dispatch(receiveAcl(acl));
};