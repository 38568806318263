import Api from '../../Api';
import {
  RECEIVE_USER_ORDERS_FAILURE,
  RECEIVE_USER_ORDERS_SUCCSES,
  RECEIVE_USER_PROFILE_FAILURE,
  RECEIVE_USER_PROFILE_SUCCESS,
  REQUEST_USER_ORDERS,
  REQUEST_USER_PROFILE,
} from './constants';

const requestUserProfile = () => ({
  type: REQUEST_USER_PROFILE,
});

export const receiveUserProfileSuccess = userProfile => ({
  type: RECEIVE_USER_PROFILE_SUCCESS,
  payload: {
    userProfile,
  },
});

const receiveUserProfileFailure = error => ({
  type: RECEIVE_USER_PROFILE_FAILURE,
  payload: {
    error,
  },
});

export const getMyUserProfile = () => async dispatch => {
  dispatch(requestUserProfile());
  try {
    const user = await Api.meProfile();
    dispatch(receiveUserProfileSuccess(user));
    return user;
  } catch (e) {
    dispatch(receiveUserProfileFailure(e));
  }
};

const requestUserOrders = () => ({
  type: REQUEST_USER_ORDERS,
});

const receiveUserOrders = data => ({
  type: RECEIVE_USER_ORDERS_SUCCSES,
  payload: {
    items: data.orders,
    total: data.total,
    next_offset: data.next_offset,
    statistics: data.statistics,
  },
});

const receiveUserOrdersFailure = error => ({
  type: RECEIVE_USER_ORDERS_FAILURE,
  payload: {
    error,
  },
});

export const getMyOrders = (offset, limit) => async dispatch => {
  dispatch(requestUserOrders());
  try {
    const response = await Api.meOrders({ offset, limit });
    dispatch(receiveUserOrders(response));
    return response;
  } catch (e) {
    dispatch(receiveUserOrdersFailure(e));
  }
};
