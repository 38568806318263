import theme from '@sportnet/ui/lib/Themes/baseTheme';

export default {
  ...theme,
  color: {
    ...theme.color,
    primary: '#006ADC',
    danger: '#e0566d',
    warning: 'rgb(247, 199, 37)',
    success: '#4cbf86',
  },
};
