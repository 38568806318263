import Button from '@sportnet/ui/lib/Button';
import FormGroup from '@sportnet/ui/lib/FormGroup';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import Label from '@sportnet/ui/lib/Label/Label';
import Modal from '@sportnet/ui/lib/Modal';
import { ModalActions, ModalContent } from '@sportnet/ui/lib/Modal/modal';
import Segment from '@sportnet/ui/lib/Segment';
import Select from '@sportnet/ui/lib/Select';
import moment from 'moment';
import { rem } from 'polished';
import React from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import styled, { withTheme } from 'styled-components';
import Api from '../../Api';
import Box from '../../Components/Box';
import { __ } from '../../utilities';
import { Status, Table, TableWrapper, Td, Th, Tr } from '../Me/Dashboard';
import RouteForm, { FORM_NAME } from './form';

const Text = styled.div`
  font-size: ${rem(13)};
`;

class RouteDetail extends React.PureComponent {
  state = {
    scriptLoaded: false,
    loadingOrders: false,
    isSubmittingEdit: false,
    activeAddress: {
      start: '',
      end: '',
    },
    drivers: [],
    route: {},
    activeOrder: null,
    addresses: [],
    orders: {
      ONGOING: [],
      WAITING: [],
      DELIVERED: [],
    },
  };
  componentDidMount() {
    this.getDrivers();
    this.getRoute();
    this.getOrders();
  }

  getDrivers = async () => {
    const { users: drivers } = await Api.adminGetUsers({ role: 'driver' });
    this.setState({
      drivers,
    });
  };

  getRoute = async () => {
    const route = await Api.getRouteById(this.props.match.params.routeId);
    this.setState({
      route,
    });
  };

  getOrders = async () => {
    this.setState({
      loadingOrders: true,
    });
    const { orders: WAITING } = await Api.adminGetOrders({
      status: 'WAITING',
      routeId: this.props.match.params.routeId,
    });
    const { orders: ONGOING } = await Api.adminGetOrders({
      status: 'ONGOING',
      routeId: this.props.match.params.routeId,
    });
    const { orders: DELIVERED } = await Api.adminGetOrders({
      status: 'DELIVERED',
      routeId: this.props.match.params.routeId,
    });
    this.setState(
      {
        loadingOrders: false,
        orders: {
          WAITING,
          ONGOING,
          DELIVERED,
        },
      },
      () => {
        this.remountMap();
      },
    );
  };

  getStatus = status => {
    switch (status) {
      case 'WAITING':
        return __('Čaká na prevzatie');
      case 'ONGOING':
        return __('Prevzatá');
      case 'STORNO':
        return __('Stornovaná');
      default:
        return __('Neznámy stav');
    }
  };

  remountMap = () => {
    const waypoints = [];
    const markers = [];
    const addresses = [];
    if (!window.google) {
      setTimeout(this.remountMap, 500);
      return;
    }
    const icons = {
      pickup: {
        url: '/assets/img/blue_marker.png',
        scaledSize: new window.google.maps.Size(25, 35), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(12.5, 17.5), // anchor
      },
      delivery: {
        url: '/assets/img/red_marker.png',
        scaledSize: new window.google.maps.Size(25, 35), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(12.5, 17.5), // anchor
      },
    };
    this.state.orders.WAITING.forEach(order => {
      waypoints.push({
        location: order.address.address,
        stopover: true,
      });
      addresses.push({
        lat: order.address.location.lat,
        lng: order.address.location.lng,
        name: order.address.address,
      });
      markers.push(order);
    });
    this.state.orders.ONGOING.forEach(order => {
      waypoints.push({
        location: order.recipient.address.formatted_address,
        stopover: true,
      });
      addresses.push({
        lat: order.recipient.address.geometry.location.lat,
        lng: order.recipient.address.geometry.location.lng,
        name: order.recipient.address.formatted_address,
      });
      markers.push(order);
    });
    this.setState({
      addresses,
    });
    const map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: 7,
      center: new window.google.maps.LatLng(48.7100473, 21.248),
    });

    let marker;
    let i;
    const bounds = new window.google.maps.LatLngBounds();
    for (i = 0; i < markers.length; i++) {
      const addressObj =
        markers[i].status === 'WAITING'
          ? markers[i].address.location
          : markers[i].recipient.address.geometry.location;
      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(addressObj.lat, addressObj.lng),
        map,
        icon: icons[markers[i].status === 'ONGOING' ? 'delivery' : 'pickup'],
      });
      bounds.extend(marker.position);
      window.google.maps.event.addListener(
        marker,
        'click',
        ((m, ix) => {
          return () => {
            this.setState({
              activeOrder: markers[ix],
            });
          };
        })(marker, i),
      );
    }
    map.fitBounds(bounds);
    const directionsDisplay = new window.google.maps.DirectionsRenderer();
    directionsDisplay.setMap(map);
    directionsDisplay.setOptions({
      draggable: false,
      suppressInfoWindows: false,
      suppressMarkers: true,
    });
    if (this.state.activeAddress.start && this.state.activeAddress.end) {
      const splice = [];
      waypoints.forEach((waypoint, index) => {
        if (
          waypoint.location === this.state.activeAddress.start ||
          waypoint.location === this.state.activeAddress.end
        ) {
          splice.push(index);
        }
      });
      splice.sort((a, b) => {
        return b - a;
      });
      splice.forEach(index => {
        waypoints.splice(index, 1);
      });
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: this.state.activeAddress.start,
          destination: this.state.activeAddress.end,
          waypoints,
          optimizeWaypoints: true,
          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        },
      );
    }
  };

  changeActiveAddress = e => {
    this.setState(
      {
        activeAddress: {
          ...this.state.activeAddress,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (this.state.activeAddress.start && this.state.activeAddress.end) {
          this.remountMap();
        }
      },
    );
  };

  changeStatus = async (orderId, status) => {
    await Api.adminChangeOrderStatus(orderId, status);
    await this.getOrders();
    this.setState({
      activeOrder: null,
      activeAddress: {
        start: '',
        end: '',
      },
    });
  };

  renderTable = orders => {
    return (
      <Table>
        <thead>
          <Tr>
            <Th>{__('Dátum zaevidovania')}</Th>
            <Th>{__('Orientačný dátum prevzatia')}</Th>
            <Th>{__('Odosielateľ')}</Th>
            <Th>{__('Prijímateľ')}</Th>
            <Th>{__('Počet / váha / cena')}</Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <Tr
              onClick={e => {
                e.stopPropagation();
                this.props.history.push(`/order/${order._id}`);
              }}
            >
              <Td>{moment(order.createdDate).format('DD.MM.YYYY')}</Td>
              <Td>{moment(order.delivery.date).format('DD.MM.YYYY')}</Td>
              <Td>
                {`${order.user.name} ${order.user.surname}`}
                <br />
                <b>ID: {order.user.identifier}</b>
                <br />
                <a
                  onClick={e => e.stopPropagation()}
                  href={`tel:${order.user.phone}`}
                >
                  {order.user.phone}
                </a>
                <br />
                {!!order.address && order.address.address}
              </Td>
              <Td>
                {`${order.recipient.name} ${order.recipient.surname}`}
                <br />
                <a
                  onClick={e => e.stopPropagation()}
                  href={`tel:${order.recipient.phone}`}
                >
                  {order.recipient.phone}
                </a>
                <br />
                {order.recipient.address.formatted_address}
              </Td>
              <Td>
                {order.delivery.count || 0}ks / {order.delivery.weight || 0}kg /{' '}
                {order.delivery.price || 0}
                {order.delivery.currency || 'GBP'}
              </Td>
              <Td>
                {order.status === 'WAITING' && (
                  <Status
                    clickable
                    background={this.props.theme.color.primary}
                    onClick={e => {
                      e.stopPropagation();
                      if (
                        window.confirm(
                          __('Skutočne si želáte zmeniť stav objednávky?'),
                        )
                      ) {
                        this.changeStatus(order._id, 'ONGOING');
                      }
                    }}
                  >
                    {__('Prevzatá')}
                  </Status>
                )}
                {order.status === 'ONGOING' && (
                  <Status
                    clickable
                    background={this.props.theme.color.success}
                    onClick={e => {
                      e.stopPropagation();
                      if (
                        window.confirm(
                          __('Skutočne si želáte zmeniť stav objednávky?'),
                        )
                      ) {
                        this.changeStatus(order._id, 'DELIVERED');
                      }
                    }}
                  >
                    {__('Doručená')}
                  </Status>
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    );
  };

  editRoute = async values => {
    this.setState({
      isSubmittingEdit: true,
    });
    const data = { driverId: values.driverId, date: values.date };

    try {
      await Api.editRoute(this.props.match.params.routeId, { data });
    } catch (e) {
      alert(__('Nepodarilo sa zmeniť trasu'));
    } finally {
      this.setState({
        isSubmittingEdit: false,
      });
    }
  };

  render() {
    const { activeOrder } = this.state;
    return (
      <div style={{ width: '100%' }}>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyADs0ZuVSl-Y8WYB8t-1HrWQvFNrKUtrT0"
          onError={e => {
            console.error(e);
          }}
          onLoad={() => {
            this.setState(
              {
                scriptLoaded: true,
              },
              () => {
                this.remountMap();
              },
            );
          }}
        />
        <Row>
          <Col xs={12}>
            <Segment>
              <Box
                header={__('Úprava údajov')}
                color={this.props.theme.color.primary}
              >
                <RouteForm
                  initialValues={this.state.route}
                  onSubmit={this.editRoute}
                  drivers={this.state.drivers}
                />
                <Button
                  loading={this.state.isSubmittingEdit}
                  onClick={() => this.props.dispatch(submit(FORM_NAME))}
                  primary
                >
                  {__('Uložiť')}
                </Button>
              </Box>
              <Box header={__('Trasa')} color={this.props.theme.color.danger}>
                <FormGroup>
                  <Label>{__('Začiatok')}</Label>
                  <Select
                    name="start"
                    value={this.state.activeAddress.start}
                    onChange={this.changeActiveAddress}
                  >
                    <option hidden />
                    {this.state.addresses.map(a => (
                      <option value={a.name}>{a.name}</option>
                    ))}
                  </Select>
                </FormGroup>
                <FormGroup>
                  <Label>{__('Koniec')}</Label>
                  <Select
                    name="end"
                    value={this.state.activeAddress.end}
                    onChange={this.changeActiveAddress}
                  >
                    <option hidden />
                    {this.state.addresses.map(a => (
                      <option value={a.name}>{a.name}</option>
                    ))}
                  </Select>
                </FormGroup>
              </Box>
            </Segment>
          </Col>
          <Col xs={12}>
            <Segment>
              <Box
                header={__('Zásielky')}
                color={this.props.theme.color.success}
              >
                {this.state.scriptLoaded && (
                  <div id="map" style={{ height: rem(500) }} />
                )}
              </Box>
            </Segment>
            <Segment>
              {!!this.state.orders.WAITING.length && (
                <Box
                  closable
                  color={({ theme }) => theme.color.primary}
                  header={__('Čakajúce zásielky')}
                >
                  <TableWrapper>
                    {this.renderTable(
                      this.state.orders.WAITING.filter(i => i.routeId),
                    )}
                  </TableWrapper>
                </Box>
              )}
              {!!this.state.orders.ONGOING.length && (
                <Box
                  closable
                  color={({ theme }) => theme.color.danger}
                  header={__('Zásielky v preprave')}
                >
                  <TableWrapper>
                    {this.renderTable(this.state.orders.ONGOING)}
                  </TableWrapper>
                </Box>
              )}
              {!!this.state.orders.DELIVERED.length && (
                <Box
                  closable
                  color={({ theme }) => theme.color.success}
                  header={__('Doručené zásielky')}
                >
                  <TableWrapper>
                    {this.renderTable(this.state.orders.DELIVERED)}
                  </TableWrapper>
                </Box>
              )}
            </Segment>
          </Col>
        </Row>
        {!!this.state.activeOrder && (
          <Modal
            isOpen={!!this.state.activeOrder}
            handleClose={() => {
              this.setState({
                activeOrder: null,
              });
            }}
          >
            <ModalContent>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{__('Odosielateľ')}</Label>
                    <Text>{`${activeOrder.user.name} ${activeOrder.user.surname}, ${activeOrder.address.address}`}</Text>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{__('Prijímateľ')}</Label>
                    <Text>{`${activeOrder.recipient.name} ${activeOrder.recipient.surname}, ${activeOrder.recipient.address.formatted_address}`}</Text>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{__('Stav')}</Label>
                    <Text>{this.getStatus(activeOrder.status)}</Text>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{__('Popis')}</Label>
                    <Text>{activeOrder.delivery.description}</Text>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{__('Spôsob platby')}</Label>
                    <Text>
                      {activeOrder.delivery.payment === '1'
                        ? __('Pri prevzatí')
                        : __('Pri doručení')}
                    </Text>
                  </FormGroup>
                </Col>
              </Row>
            </ModalContent>
            <ModalActions>
              &nbsp;
              <div>
                <Button
                  danger
                  onClick={() => {
                    this.setState({
                      activeOrder: null,
                    });
                  }}
                >
                  {__('Zavrieť')}
                </Button>
                &nbsp;
                {activeOrder.status === 'WAITING' && (
                  <Button
                    primary
                    loading={this.state.loadingOrders}
                    onClick={() => {
                      if (
                        window.confirm(
                          __('Skutočne si želáte zmeniť stav objednávky?'),
                        )
                      ) {
                        this.changeStatus(activeOrder._id, 'ONGOING');
                      }
                    }}
                  >
                    {__('Zásielka bola prevzatá')}
                  </Button>
                )}
                {activeOrder.status === 'ONGOING' && (
                  <Button
                    success
                    loading={this.state.loadingOrders}
                    onClick={() => {
                      if (
                        window.confirm(
                          __('Skutočne si želáte zmeniť stav objednávky?'),
                        )
                      ) {
                        this.changeStatus(activeOrder._id, 'DELIVERED');
                      }
                    }}
                  >
                    {__('Zásielka bola doručená')}
                  </Button>
                )}
                &nbsp;
                <Button
                  onClick={() => {
                    this.props.history.push(`/order/${activeOrder._id}`);
                  }}
                >
                  {__('Prejsť na detail')}
                </Button>
              </div>
            </ModalActions>
          </Modal>
        )}
      </div>
    );
  }
}

export default withTheme(withRouter(connect()(RouteDetail)));
