const namespace = 'APP';

export const REQUEST_USER = `${namespace}/REQUEST_USER`;
export const RECEIVE_USER_SUCCESS = `${namespace}/RECEIVE_USER_SUCCESS`;
export const REMOVE_USER = `${namespace}/REMOVE_USER`;
export const ERROR_CLEAR = `${namespace}/ERROR_CLEAR`;
export const ERROR_RECEIVED = `${namespace}/ERROR_RECEIVED`;
export const SUCCESS_RECEIVED = `${namespace}/SUCCESS_RECEIVED`;
export const SET_BREADCRUMBS = `${namespace}/SET_BREADCRUMBS`;
export const RECEIVE_CODELIST_SUCCESS = `${namespace}/RECEIVE_CODELIST_SUCCESS`;
export const RECEIVE_ACL = `${namespace}/RECEIVE_ACL`;