import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { getProp } from 'sportnet-utilities';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import configureStore from './configureStore';
import ApplicationWrapper from './Containers/ApplicationWrapper';
import theme from './theme';

// eslint-disable-next-line
const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext');
  html, body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    background: rgb(249, 249, 249);
  }
  #root {
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: #006ADC;
  }
`;

const defaultState = null;
let state = {};
if (typeof window !== 'undefined') {
  state = getProp(window, ['__REDUX_STATE__']);
}

// Initialise redux store
const store = configureStore(
  state !== '{{SSR_INITIAL_STATE}}' ? state : defaultState,
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Switch>
          <ApplicationWrapper />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
